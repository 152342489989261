import React from "react";

import { Avatar, Col, Card, List, Space } from "antd";
import { Link } from "gatsby";
import "src/components/productCategories/style.scss";

import { CheckOutlined } from "@ant-design/icons";

import laFlorida from "src/components/referencias/images/exterior/climateCoating-thermoProtect-la-florida-thumbnail.png";
import elParaiso from "src/components/referencias/images/exterior/climateCoating-thermoProtect-el-campanario-el-paraiso.jpg";
import laMoraleja from "src/components/referencias/images/exterior/climateCoating-thermoProtect-la-moraleja-madrid-thumbnail.jpg";
import sotogrande from "src/components/referencias/images/exterior/climateCoating-thermoProtect-sotogrande-cadiz-thumbnail.jpg";

import lcFirst from "utils/lcFirst";

import thermoProtectAvatarImg from "src/components/products/images/thermoProtectAvatar.jpg";

const { Meta } = Card;

export const ProductCategoryExteriorComponent = ({}) => {
  const listData = [];

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  return (
    <div className="ccContent referencias">
      <List
        {...{
          itemLayout: "vertical",
          size: "large",
          pagination: {
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 5,
          },
          dataSource: listData,
          footer: (
            <div>
              Referencias de proyectos que han usado{" "}
              <b>ClimateCoating en España</b>
            </div>
          ),

          renderItem: (item) => (
            <List.Item
              key={item.title}
              actions={item.list.map((feature, key) => (
                <IconText
                  {...{
                    icon: CheckOutlined,
                    text: feature,
                    key,
                  }}
                />
              ))}
              extra={<img width={272} alt="logo" src={item.imgSrc} />}
            >
              <List.Item.Meta
                avatar={<Avatar src={item.avatar} />}
                title={
                  <Link
                    to={`/productos/${item.productName.toLowerCase()}`}
                    className={lcFirst(item.productName)}
                  >
                    {item.title}
                  </Link>
                }
                description={item.description}
              />
              {item.content}
            </List.Item>
          ),
        }}
      />
    </div>
  );
};

export default ProductCategoryExteriorComponent;
