import React from "react";

import ReferenciasIndustrial from "src/components/referencias/Industrial";
import { LayoutReferencia } from "src/layout/LayoutReferencia";

const ReferenciasIndustrialPage = (props) => (
  <LayoutReferencia
    {...{
      activePage: "industry",
      pageTitle: "Referencias de ClimateCoating para Industria",
      pageTitleJSX: (
        <span>
          <span className="mainTitle colored industrySpecial">
            Rendimiento Óptimo en situaciones límite:
          </span>
          <span className="secondaryTitle">
            Referencias en España de ClimateCoating para uso Industrial
          </span>
        </span>
      ),
      pageName: "industrial",
      pageDescription:
        "Referencias en España de ClimateCoating para uso Industrial",
    }}
  >
    <ReferenciasIndustrial
      {...{
        xs: 1,
        sm: 2,
        xl: 4,
        xxl: 4,
      }}
    />
  </LayoutReferencia>
);

export default ReferenciasIndustrialPage;
